import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";
const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirect = (name: any) => {
    navigate(name);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundImage: "url(/landing_BG.svg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          textAlign: "center",
        }}
      >
        <img src={"/white_logo.svg"} width={250} style={{ marginBottom: 35 }} />

        <div style={{}}>
          <Button
            variant="contained"
            className="registercomplaint"
            onClick={() => redirect("services")}
          >
            Register a Complaint <br />
            शिकायत दर्ज करना
          </Button>
          <Button
            variant="contained"
            className="adminlogin"
            onClick={() => redirect("signin")}
          >
            Admin Login
            <br />
            व्यवस्थापक लॉगिन
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Landing;
