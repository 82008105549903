import React, { useState } from 'react'
import { TextField, Button, Box, Link } from '@mui/material'
import Text from '../../common/Text'
import { useNavigate } from 'react-router-dom'
import { setLocalStorageItem } from '../../../services/storageService'
import axios from 'axios'
import './LoginBanner.css'
import i18n from '../../../config/i18'
// import { useTranslation } from "react-i18next";

const SigninForm = () => {
  // const { t } = useTranslation();
  const [clicksubmit, setClickSubmit] = React.useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [errorMessage] = React.useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    // Basic validation
    // if (!username || !password) {
    //   setError('Both username and password are required.')
    //   return
    // }

    // try {
    //   const response = await fetch('https://myvoicemyhelp.com/login', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ username, password }) // Sending the username and password in the request body
    //   })

    //   const data = await response.json()

    //   if (response.ok) {
    //     alert('Login successful!')
    //     localStorage.setItem('isLogged', 'true')
    //     navigate('/analytics')
    //   } else {
    //     setError(data.error || 'Login failed, please try again.')
    //   }
    // } catch (err) {
    //   console.error('Error during login:', err)
    //   setError('An error occurred during login.')
    // }
     const formData={'username': username,'password': password}
     axios.post( 'https://myvoicemyhelp.com/login',formData)
     .then(result => {
         console.log(result);
         if(result.data === "Success"){
             console.log("Login Success");
             alert('Login successful!')
             localStorage.setItem("isLogged", "true")
             navigate('/analytics');
         }
         else{
             alert('Incorrect password! Please try again.');
         }
     })
     .catch(err => console.log(err));
  }
  const handleLanguageChange = (val: string) => {
    i18n.changeLanguage(val)
    setLocalStorageItem('languageSelect', val)
    if (val === 'en') {
      setClickSubmit(false)
    } else {
      setClickSubmit(true)
    }
  }

  return (
    <div style={{ marginRight: 20 }}>
      <form onSubmit={handleSubmit}>
        <Box>
          <Text fontSize={24} fontWeight={600} style={{ marginTop: 25 }}>
            Welcome to
          </Text>
        </Box>
        <Box
          component={'img'}
          src={`./logo.svg`}
          style={{ marginTop: 15, width: 150 }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              marginBottom: 3,
              marginTop: 20
            }}
          >
            Username
          </Text>

          <Text
            style={{
              marginLeft: 4,
              marginBottom: 4
            }}
            color='#F13939'
          >
            *
          </Text>
        </Box>

        <TextField
          fullWidth
          size='small'
          variant='outlined'
          onChange={event => setUsername(event.target.value)}
          placeholder='Enter your username'
        />
        {errorMessage && (
          <Text
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
              width: '100%'
            }}
            fontSize={12}
            fontWeight={400}
            color='#F13939'
          >
            This user is not registered with MVMH, please Sign Up
          </Text>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              marginBottom: 3,
              marginTop: 25
            }}
          >
            Password
          </Text>

          <Text
            style={{
              marginLeft: 4,
              marginBottom: 4
            }}
            color='#F13939'
          >
            *
          </Text>
        </Box>

        <TextField
          fullWidth
          size='small'
          variant='outlined'
          onChange={event => setPassword(event.target.value)}
          placeholder='Enter your password'
        />
        {errorMessage && (
          <Text
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
              width: '100%'
            }}
            fontSize={12}
            fontWeight={400}
            color='#F13939'
          >
            This email is not registered with MVMH, please Sign Up
          </Text>
        )}

        <Text
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            width: '100%'
          }}
          fontSize={14}
          fontWeight={500}
          color='#910c86'
        >
          Forgot Password?
        </Text>

        <Button
          sx={{
            width: '100%',
            backgroundColor: '#910c86',
            borderRadius: 1,
            textTransform: 'none',
            marginTop: 5
          }}
          className='btn-primary'
          type='submit'
        >
          <Text fontSize={18} fontWeight={600} color='#FFF'>
            Sign In
          </Text>
        </Button>
      </form>
      <Text style={{ marginTop: 15, marginBottom: 15 }}>
        By clicking Sign In, you agree to MVMH Terms of Services and Privacy
        Policy.
      </Text>

      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: '#E9E9E9'
        }}
      ></Box>
      <Text
        fontSize={14}
        fontWeight={600}
        color='#353535'
        style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}
      >
        Choose Language
      </Text>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 5
        }}
      >
        <Link
          component='button'
          style={{
            marginRight: 25,
            fontSize: 16
          }}
          className={!clicksubmit ? 'underline' : 'non-underline'}
          onClick={() => {
            handleLanguageChange('en')
          }}
        >
          English
        </Link>
        <Link
          component='button'
          style={{ fontSize: 16 }}
          className={clicksubmit ? 'underline' : 'non-underline'}
          onClick={() => {
            handleLanguageChange('es')
          }}
        >
          हिंदी
        </Link>
      </Box>
    </div>
  )
}

export default SigninForm
