export const MenuListItems = [
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: true,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "Distributors",
    cssClass: "admin-css",
    name: "Dashboard",
    tname: "डैशबोर्ड",
    url: "/dashboard",
    toolTip: "Dashboard",
    displayOrder: 1,
    menuImage: "pmo.svg",
  },
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: true,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "Distributors",
    cssClass: "admin-css",
    name: "Services",
    tname: "सेवाएं",
    url: "/services",
    toolTip: "Services",
    displayOrder: 2,
    menuImage: "services.svg",
  },
  {
    moduleId: 0,
    pModuleId: 0,
    isSubMenu: true,
    subMenu: [],
    features: [],
    childMenu: [],
    parentModuleName: "Distributors",
    cssClass: "admin-css",
    name: "Analytics",
    tname: "विश्लेषण",
    url: "/analytics",
    toolTip: "Analytics",
    displayOrder: 3,
    menuImage: "analytics.svg",
  },
];
