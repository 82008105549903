// Privacy.js

import React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import "./Privacy.css";

const Privacy = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <div className="terms-page">
        <nav className="navbar">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            className="logoContainer"
          >
            <a href="/" style={{ display: "flex", alignItems: "center" }}>
              <img className="menu-logo" alt="menu-logo" src="/logo.svg" />
            </a>
          </Box>
        </nav>
        <div
          className="privacy-heto-section
"
        >
          <header>
            <h1 className="headText">Privacy Policy</h1>
          </header>
          <div className="hero-hired-wrap">
            <div className="term-section">
              <p>
                MVMHPro™ is a service which includes a web and mobile platform.
                We require your information to share with the MVMHPro™
                community. The information we collect is used to access
                features, like communicating with other Users and applying to
                job opportunities. When reading the policies below, you can also
                reference our Terms of Service for some detailed definitions. We
                hope you take the time to read how we protect your privacy.
              </p>
            </div>
          </div>
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">What information is collected?</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div className="term-section first">
                <p>
                  All information that you provided to us in any way will be
                  stored. Whether through the app, website, email, contact pages
                  etc. In addition, we obtain some information automatically. We
                  will collect and store the following
                </p>
                <ul>
                  <li>Your contact email address</li>
                  <li>
                    Your email address associated with your PayPal account
                  </li>
                  <li>
                    Mobile telephone number or any contact number provided
                  </li>
                  <li>Any photos provided for your profile</li>
                  <li>Any information contained in your uploaded resume</li>
                  <li>Home address including zip code</li>
                  <li>Educational background history</li>
                  <li>
                    Eligibility to Work Status (U.S. Citizen, H1-B Visa etc.)
                  </li>
                  <li>
                    Transcripts of any information discussed with MVMHPro™
                    personnel
                  </li>
                  <li>Phone, computer or tablet operating system</li>
                  <li>Location Data through GPS or WiFi access points</li>
                  <li>Mobile Network</li>
                  <li>Date and time of visits to our site or use of the app</li>
                  <li>Read receipts for emails opened from MVMHPro™</li>
                  <li>IP address</li>
                  <li>Mouse clicks, mouse movements and scrolling activity</li>
                  <li>Referring URL to MVMHPro™</li>
                </ul>
              </div>

              <hr className="underline" />
            </div>
          </div>
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">How are cookies being used?</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  To help you have a faster and customized experience, MVMHPro™
                  will store bits of information on your computer. This
                  information, also known as cookies, is transferred to your
                  hard drive through your browser while accessing MVMHPro™. Some
                  of the information mentioned above, is collected automatically
                  through cookies. Most browsers have options to turn off this
                  feature.
                </p>
              </div>
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">Who is information shared with?</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Your information is used to communicate effectively with other
                  Users. In addition, we use your information to facilitate our
                  services and make improvements. We share your information with
                  the following:
                </p>
                <ul>
                  <li>
                    Our parent company, Compunnel Software Group, Inc. and all
                    associated entities
                  </li>
                  <li>Other MVMHPro™ Users</li>
                  <li>Other non-registered Users at your discretion</li>
                  <li>Legal authorities when ordered by subpoena</li>
                  <li>PayPal for payment processing</li>
                  <li>
                    Third party software and/or service to provide analytics
                    information and to help improve usability and the user
                    experience. Note, these are independent software and/or
                    service providers and MVMHPro™, Compunnel Software Group,
                    Inc and all of its associated entities do not take any
                    liability about the information collection policies of these
                    providers, or about any kind of issue, legal or otherwise,
                    relating thereto. Third Party Service Provider’s include,
                    but are not limited to:
                    <ul className="styled-ul-a">
                      <li>
                        UXCam, which has a privacy policy available at
                        https://uxcam.com/privacy
                      </li>
                      <li>
                        Lucky Orange analytics system, which may record mouse
                        clicks, mouse movements and scrolling activity. Lucky
                        Orange does not track this activity on any site that
                        does not use the Lucky Orange system. You can choose to
                        disable the Lucky Orange Service at
                        http://www.luckyorange.com/disable.php
                      </li>
                    </ul>
                  </li>
                  <li>
                    Data obtained through the Short Code program is not shared
                    with any third-parties for their marketing or sales
                    reasons/purposes.
                  </li>
                  <li>
                    Under no circumstances, the collected data is shared with
                    any Third party software and/or service with intentions of
                    financial gains, directly or indirectly.
                  </li>
                </ul>
              </div>

              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">How is information kept secure?</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  Security measures are used throughout all areas of our service
                  to protect your information from unauthorized access or
                  misuse. We take the following precautions to enhance security:
                </p>

                <ul>
                  <li>Data encryption during transit</li>
                  <li>Data encryption during storage</li>
                  <li>Industry standard internet protocols</li>
                  <li>Password authentication</li>
                  <li>Physical and managerial procedures</li>
                </ul>
                <p>
                  Although these precautions are in place, no exchange of data
                  over the internet can be guaranteed as 100% secure.
                  Unauthorized interception is possible in even the most secure
                  of websites and applications. We are committed to providing
                  above industry standard security.
                </p>
              </div>
              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">How can information be controlled?</h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  There are features built into our service allowing you to
                  ‘opt-in’ at any time. If there is information you wish to have
                  removed, please contact support@myvoicemyhelp.com with your
                  request.
                </p>
              </div>

              <hr className="underline" />
            </div>
          </div>{" "}
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">
              Is there any additional information I should know?
            </h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  There are a few more aspects that are important and you must
                  acknowledge.
                </p>
                <ul>
                  <li>
                    MVMHPro™ is designed for adults 18 years and older unless
                    represented by a legal guardian of age.
                  </li>
                  <li>
                    We can update our policies at any time. The most up-to-date
                    policy will be considered the only policy at the time of
                    using our services.
                  </li>
                  <li>
                    We are not responsible, monitoring or making and guarantees
                    or claims of the policies of other sites we link to.
                  </li>
                  <li>
                    Our privacy policies are designed to follow any applicable
                    laws or guidelines following the jurisdiction of New York
                    State in the United States of America.
                  </li>
                </ul>
              </div>

              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
          <div className="hero-hired-wrap">
            <h3 className="heading-h3">
              What’s the best way to contact MVMHPro™?
            </h3>
            <div style={{ display: "flex", alignContent: "center" }}>
              <div
                className="term-section first"
                style={{
                  width: "80%",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginRight: "80px",
                  textAlign: "justify",
                }}
              >
                <p>
                  We are available to answer your questions and concerns. If you
                  have a question, then others may have a question too. Please
                  ask away so we can improve our policies to make them as easy
                  to understand as possible. Contact support@myvoicemyhelp.com
                </p>
              </div>

              <hr className="underline" />
            </div>
          </div>
          <hr className="underline" />
        </div>

        <footer className="footer">
          <div className="copyright">
            <div className="footerText">
              <a href="/terms">Terms of use</a>
              <span>|</span>
              <a href="/privacy">Privacy Policy</a>
              <span>|</span>
              <a href="#" onClick={handleOpen}>
                Contact Us
              </a>
              <Modal
                open={open}
                onClose={handleClose}
                className="modal-contact-us"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={(theme) => ({
                  [theme.breakpoints.only("xs")]: {
                    borderRadius: 70,
                  },
                })}
              >
                <Box className="modal-container">
                  <div className="modal-header">
                    <Typography className="modal-title">Contact Us</Typography>
                    <ClearIcon className="close-icon" onClick={handleClose} />
                  </div>
                  <div className="modal-content">
                    <Typography
                      id="modal-modal-description"
                      sx={{ mb: 2 }}
                      className="modal-description"
                    >
                      Please write to us on link{" "}
                      <a
                        href="mailto:support@myvoicemyhelp.com"
                        style={{ color: "black ", textDecoration: "none" }}
                      >
                        support@myvoicemyhelp.com
                      </a>{" "}
                      We typically respond within 24 hours on all business days.
                    </Typography>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="footerText">
              &copy; {new Date().getFullYear()} MVMH Inc. All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Privacy;
