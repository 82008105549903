import React from "react";
import Header from "../common/header/Header";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{ marginLeft: 120, marginTop: 20, width: "calc(100% - 140px)" }}
    >
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "calc(100vh - 80px)",
        }}
      >
        <img src={`/icons/notfound.svg`} alt="Page Not Found" />
        <Typography
          component="h2"
          mt={3}
          mb={2}
          style={{
            fontFamily: "Roboto",
            fontSize: 24,
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          {t("page-notfound")}
        </Typography>
        <Typography component="h6" mt={3} mb={2}>
          {t("sorry-we-not-find")}
        </Typography>
      </div>
    </div>
  );
};
export default PageNotFound;
