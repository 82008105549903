import React from 'react'
import './Home.css'
import Header from '../common/header/Header'

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Home = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{ marginLeft: 120, marginTop: 20, width: 'calc(100% - 140px)' }}
    >
      <Header />
      <Typography
        component='h2'
        mt={3}
        mb={2}
        style={{
          fontFamily: 'Roboto',
          fontSize: 24,
          fontWeight: 600,
          lineHeight: 'normal'
        }}
      >
        {t('home')}
      </Typography>
    </div>
  )
}

export default Home
