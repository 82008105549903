import React, { useEffect, useState } from 'react'
import Header from '../common/header/Header'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import Box from '@mui/material/Box'

const columns: GridColDef[] = [
  { field: 'id', headerName: 'S.No.', width: 50 },
  {
    field: 'dateOfCase',
    headerName: 'Date of Case / केस की तारीख',
    width: 120
  },
  {
    field: 'caseNo',
    headerName: 'Case No / केस नं',
    width: 150
  },
  {
    field: 'fullname',
    headerName: 'Complainant Name / शिकायतकर्ता',
    width: 150
  },
  {
    field: 'age',
    headerName: 'Age /आयु',
    width: 50
  },
  {
    field: 'telephone',
    headerName: 'Contact No / संपर्क नंबर',
    width: 150
  },
  {
    field: 'address',
    headerName: 'Address / पता',
    width: 200
  },
  {
    field: 'services',
    headerName: 'Type of case / केस का प्रकार',
    width: 150
  },
  {
    field: 'complanintBrief',
    headerName: 'Complainant in Brief / शिकायतकर्ता संक्षेप में',
    width: 300
  }
]

const Analytics = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [isLogged, setIsLogged] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (localStorage.getItem('isLogged')) {
      setIsLogged(true)
      // Fetch data when the component mounts
      fetch('https://myvoicemyhelp.com/api/mren/getFormsData') // Replace with your API URL
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json() // Parse the JSON data
        })
        .then(data => {
          setData(data) // Store the fetched data in state
        })
        .catch(error => {
          setError(error.message) // Handle any errors
        })
    } else {
      setIsLogged(false)
    }
  }, []) // Empty dependency array means this will run once, after initial render

  return (
    <div
      style={{ marginLeft: 120, marginTop: 20, width: 'calc(100% - 140px)' }}
    >
      <Header />
      <Typography
        component='h2'
        mt={3}
        mb={2}
        style={{
          fontFamily: 'Roboto',
          fontSize: 24,
          fontWeight: 600,
          lineHeight: 'normal'
        }}
      >
        {t('analytics')}
      </Typography>
      <Box sx={{ height: 800, width: '100%' }}>
        {isLogged ? (
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25
                }
              }
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        ) : (
          <Typography
            component='h2'
            mt={3}
            mb={2}
            style={{
              fontFamily: 'Roboto',
              fontSize: 24,
              fontWeight: 600,
              lineHeight: 'normal'
            }}
          >
            You don't have permission
          </Typography>
        )}
      </Box>
    </div>
  )
}
export default Analytics
