import React from 'react'
// import { useTranslation } from "react-i18next";

const LoginBanner = () => {
  // const { t } = useTranslation();
  return (
    <div
      style={{
        backgroundImage: 'url(./woman.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        borderRadius: '30px 0 0px 30px',
        paddingLeft: '20px',
        paddingTop: '20px'
      }}
    ></div>
  )
}

export default LoginBanner
