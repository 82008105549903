// React Imports
import React, { CSSProperties, FC, ReactNode } from "react";

// UI Imports
import { Typography } from "@mui/material";

interface TextProps {
  children: ReactNode;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  style?: CSSProperties;
}

const Text: FC<TextProps> = (props) => {
  return (
    <Typography
      style={{
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        color: props.color,
        ...props.style,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default Text;

Text.defaultProps = {
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: 400,
};
