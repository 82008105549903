import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { MenuListItems } from "./MenuListItems";
import "../sidebar/Navigation.css";
import lng from "../../../config/i18";

const Navigation = () => {
  const navigate = useNavigate();
  const { collapseSidebar } = useProSidebar();
  const [nav] = useState(MenuListItems);
  const [icon, setIcon] = useState(true);
  useEffect(() => {
    //collapseSidebar();
  }, []);

  const navigateURL = (res: any) => {
    navigate(res.url);
  };
  const lang: any = lng;
  const renderSubMenu = (item: any) => {
    return (
      <Tooltip
        title={lang.language === "en" ? item.toolTip : item.tname}
        placement="right"
      >
        <Link
          onClick={() => {
            navigateURL(item.url);
          }}
          to={getLinkUrl(item)}
        >
          <MenuItem
            suffix={lang.language === "en" ? item.name : item.tname}
          ></MenuItem>
        </Link>
      </Tooltip>
    );
  };
  const getLinkUrl = (res: any) => {
    return res.url;
  };
  return (
    <div className="my-sidebar">
      <Sidebar defaultCollapsed={true}>
        <Menu>
          <div className="main-menu-div">
            <li
              onClick={() => {
                collapseSidebar();
                setIcon(!icon);
              }}
            >
              {icon ? (
                <img
                  style={{ marginLeft: 40, marginBottom: 15 }}
                  src={"/menu/toogle.svg"}
                  alt="menu-img"
                  className="menu-img"
                />
              ) : (
                <img
                  style={{ marginLeft: 40, marginBottom: 15 }}
                  src={"/menu/close.svg"}
                  alt="menu-img"
                  className="menu-img"
                />
              )}
            </li>
            {nav?.map((res) => {
              return res.childMenu && res.childMenu.length ? (
                <Link
                  key={res.moduleId}
                  className="my-menu"
                  data-testid={`menu-item-${res.moduleId}`}
                  to={getLinkUrl(res)}
                >
                  <Tooltip title={res.name} placement="right">
                    <SubMenu
                      label={res.name}
                      icon={
                        <img
                          className={res.cssClass}
                          alt={res.name}
                          src={`/menu/${res.menuImage}`}
                        />
                      }
                    >
                      {res.childMenu.map((item) => {
                        return renderSubMenu(item);
                      })}
                    </SubMenu>
                  </Tooltip>
                </Link>
              ) : (
                <Tooltip
                  title={lang.language === "en" ? res.toolTip : res.tname}
                  placement="right"
                >
                  <MenuItem
                    suffix={lang.language === "en" ? res.name : res.tname}
                    onClick={() => {
                      navigateURL(res);
                    }}
                  >
                    <img src={"/menu/" + res.menuImage} alt="menu-img" />
                  </MenuItem>
                </Tooltip>
              );
            })}
          </div>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Navigation;
