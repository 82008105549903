import React from "react";
import { Card, Grid } from "@mui/material";
import LoginBanner from "./login/LoginBanner";
import SigninForm from "./login/SigninForm";
import LoginFooter from "./login/Footer";
// import SignInOtp from "./login/SignInOtp";

interface SignInProps {}
const SignIn = () => {
  return (
    <div style={{ marginLeft: "10%", marginTop: 50, width: "80%" }}>
      <Card style={{ borderRadius: "30px 30px 30px 30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LoginBanner />
          </Grid>
          <Grid item xs={6}>
            <SigninForm />
            {/* { <SignInOtp /> } */}
          </Grid>
        </Grid>
      </Card>

      <LoginFooter />
    </div>
  );
};

export default SignIn;
